import { Button, CopyButton, EditMini } from '@dltru/dfa-ui'
import classname from 'classnames/bind'
import { FC, useState } from 'react'

import { IDfaLink } from '@store/dfa/types'

import AddLinkModal from './AddlinkModal'
import style from './style.m.less'

const cn = classname.bind(style)

interface IExtraLink {
    onClick?: () => void
    href: string
    link: string
    type: keyof IDfaLink
    isEditable: boolean
    linkOriginForTitle: string
}

const ExtraLink: FC<IExtraLink> = ({ type, href, link, isEditable, linkOriginForTitle }) => {
    const [isShowAddLink, setIsShowAddLink] = useState(false)

    return (
        <>
            <div className={cn(style.extraLinkContainer)}>
                <a className={cn(style.extraLink)} href={href} target="_blank" rel="noreferrer">
                    {link}
                </a>
                <div className={cn(style.extraLinkExtra)}>
                    {isEditable && (
                        <Button
                            className={cn(style.extraLinkButton)}
                            icon={<EditMini />}
                            borderRadius={8}
                            type="link"
                            onClick={() => {
                                setIsShowAddLink(true)
                            }}
                        ></Button>
                    )}
                    {link && (
                        <span className={style.hiddenButton}>
                            <CopyButton text={href} />
                        </span>
                    )}
                </div>
            </div>
            <AddLinkModal
                type={type}
                isModalVisible={isShowAddLink}
                setIsModalVisible={setIsShowAddLink}
                linkOriginForTitle={linkOriginForTitle}
            />
        </>
    )
}

export default ExtraLink
