import { DfaStatusEnum, preStatuses } from '@dltru/dfa-models'
import { EMPTY_FIELD_VALUE, Infotag } from '@dltru/dfa-ui'
import classname from 'classnames/bind'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { IDfaLink } from '@store/dfa/types'

import AddLinkButton from './AddLinkButton'
import ExtraLink from './ExtraLink'
import style from './style.m.less'

const cn = classname.bind(style)

const getLinksElement = (
    link: { [key in keyof IDfaLink]: string },
    linkOriginForTitle: string,
    isEditable: boolean,
    isProcessing: boolean,
): React.ReactElement => {
    if (isProcessing) {
        return <div className={cn(style.infotagValue)}>{EMPTY_FIELD_VALUE}</div>
    }
    const [key, value] = Object.entries(link)[0] as unknown as [keyof IDfaLink, string]

    if (!value && isEditable) {
        return <AddLinkButton type={key} linkOriginForTitle={linkOriginForTitle} />
    }

    return value ? (
        <ExtraLink
            type={key}
            href={value}
            link={value}
            isEditable={isEditable}
            linkOriginForTitle={linkOriginForTitle}
        />
    ) : (
        <span className={cn(style.greySpan)}>Сайт не указан</span>
    )
}

export const Links: FC = () => {
    const { ois_link, emitter_link, status, emitter_id } = useSelector(
        dfaDetailsSelector.selectDfaDetails,
    )
    const userUuid = useSelector(authSelector.selectUserUid)

    const isEditable = userUuid === emitter_id && status === DfaStatusEnum.waiting_for_links
    const isProcessing = preStatuses.includes(status)

    return (
        <>
            <Infotag label="Эмитент">
                {getLinksElement({ emitter_link }, 'эмитента', isEditable, isProcessing)}
            </Infotag>
            <Infotag label="Оператор ИС">
                {getLinksElement({ ois_link }, 'оператора ИС', false, isProcessing)}
            </Infotag>
        </>
    )
}
